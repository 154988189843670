
import Vue, { type PropType } from "vue"
import {
  CopilotMissingFieldsLabels,
  type CamerasByExid,
  type CopilotMissingField,
  type ProjectsByExid,
} from "@evercam/shared/types"
import CtkDateTimePicker from "@evercam/shared/components/CtkDateTimePicker.vue"

export default Vue.extend({
  name: "CopilotMissingFieldsForm",
  components: {
    CtkDateTimePicker,
  },
  props: {
    messageId: {
      type: [String, Number],
      required: true,
    },
    missingFields: {
      type: Array as PropType<CopilotMissingField[]>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    projectExid: {
      type: String,
      default: "",
    },
    cameraExid: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      missingFieldsLabels: CopilotMissingFieldsLabels,
      values: this.missingFields.reduce((acc, field) => {
        acc[field.name] = field.value

        return acc
      }, {} as Record<string, string | null>),
    }
  },
  computed: {
    projects(): { title: string; value: string; image: string }[] {
      return Object.entries(this.projectsByExid).map(([key, value]) => ({
        title: value.name,
        value: key,
        image: this.getProjectThumbnailUrl(key),
      }))
    },
    cameras(): { title: string; value: string; image: string }[] {
      const allCameras = Object.entries(this.camerasByExid).map(
        ([key, value]) => ({
          title: value.name,
          value: key,
          image: this.getCameraThumbnailUrl(value.thumbnailUrl),
        })
      )

      if (!this.values?.projectExid && !this.projectExid) {
        return allCameras
      }

      return allCameras.filter(
        (camera) =>
          this.camerasByExid[camera.value].project.id ===
          (this.values.projectExid ?? this.projectExid)
      )
    },
    startDate(): string | undefined {
      if (!this.values?.projectExid) {
        return
      }

      return this.camerasByExid[this.values.projectExid!]?.createdAt
    },
    missingFieldsProjectExid(): boolean {
      return (
        Object.keys(this.values).includes("projectExid") &&
        !this.values.projectExid
      )
    },
  },
  methods: {
    getFormattedDate(date: string) {
      return this.$moment(date).toISOString()
    },
    onSubmit() {
      this.$emit("missing-fields-completed", {
        id: this.messageId,
        missingFields: this.values,
      })
    },
    getProjectThumbnailUrl(projectExid: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${this.$config.public.apiURL}/projects/${projectExid}/thumbnail?authorization=${this.token}`
      )
    },
    getCameraThumbnailUrl(thumbnailUrl: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${thumbnailUrl}?authorization=${this.token}`
      )
    },
  },
})
