
import Vue, { type PropType } from "vue"
import {
  type CamerasByExid,
  type CopilotSystemToolCallResponse,
  CopilotToolId,
  type Media,
  type ProjectsByExid,
} from "@evercam/shared/types"
import CopilotToolCallResultMediaClip from "@evercam/shared/components/copilot/CopilotToolCallResultMediaClip.vue"

export default Vue.extend({
  name: "CopilotChat",
  components: {
    CopilotToolCallResultMediaClip,
  },
  props: {
    result: {
      type: Object as PropType<CopilotSystemToolCallResponse>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    CopilotToolId() {
      return CopilotToolId
    },
    mediaClip(): Media {
      return this.result.output as Media
    },
  },
})
