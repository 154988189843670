
import Vue, { type PropType } from "vue"
import {
  type CameraExid,
  type CamerasByExid,
  type Media,
  type ProjectExid,
} from "@evercam/shared/types"
import MediaClip from "@evercam/shared/components/Medias/MediaClip"

export default Vue.extend({
  name: "CopilotChat",
  components: {
    MediaClip,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
    mediaClip: {
      type: Object as PropType<Media>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
  },
  computed: {
    cameraExid(): CameraExid | undefined {
      return this.mediaClip?.cameraExid
    },
    projectExid(): ProjectExid {
      if (this.cameraExid) {
        return this.camerasByExid[this.cameraExid].project.id
      }

      return /projects\/(.*)\/media-hub/.exec(
        this.mediaClip?.thumbnailUrl!
      )?.[1] as ProjectExid
    },
    cameraName(): string {
      return (this.mediaClip?.cameraName ||
        this.camerasByExid[this.cameraExid!]?.name ||
        this.mediaClip.title?.split(" - ")?.[0]) as string
    },
    formattedDuration(): string {
      const { fromDate, toDate } = this.mediaClip
      const diff = this.$moment(toDate).diff(this.$moment(fromDate))
      const duration = this.$moment.duration(diff)

      if (duration.asMinutes() < 1) {
        return `${duration.seconds()} seconds`
      } else if (duration.asMinutes() < 60) {
        return `${Math.floor(duration.asMinutes())} minute${
          Math.floor(duration.asMinutes()) > 1 ? "s" : ""
        } ${duration.seconds()} seconds`
      } else {
        return `${Math.floor(duration.asHours())} hour${
          Math.floor(duration.asHours()) > 1 ? "s" : ""
        } ${duration.minutes()} minute${duration.minutes() > 1 ? "s" : ""}`
      }
    },
    mediaUrl(): string {
      return `/v2/projects/${this.projectExid}/media-hub/${this.mediaClip.exid}`
    },
  },
})
